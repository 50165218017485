<template>
  <div class="bg">

    <NavHeader />

    <div class="member">

      <div class="Center">
        <div class="Center-mine">
          <div class="Center_left">
            <div class="order">
              <el-row class="tac">
                <el-col :span="12">
                  <el-menu
                    active-text-color="#fe6925"
                    :default-openeds="openeds"
                    :default-active="$route.path"
                    class="el-menu-vertical-demo"
                    @open="handleOpen"
                    @close="handleClose"
                    :router="true"
                  >
                    <el-submenu index="1">
                      <span style="font-size:14px;font-weight: 700;width:100px;">会员中心</span>
                      <el-menu-item index="/member-index">中心首页</el-menu-item>
                      <el-menu-item index="/member-setting">账户设置</el-menu-item>
                      <el-menu-item index="/member-order">我的订单</el-menu-item>
                      <el-menu-item index="/member-supply" v-if="isShowSupply()">我的供需</el-menu-item>
                      <el-menu-item index="/member-supply" v-else>我的需求</el-menu-item>
                      <el-menu-item index="/member-consult">我的议价</el-menu-item>
                      <el-menu-item index="/member-seek">我的询价</el-menu-item>
                      <el-menu-item index="/member-evaluate">我的评价</el-menu-item>
                      <el-menu-item index="/member-complaint">我的投诉</el-menu-item>
                      <el-menu-item index="/member-message">站内消息</el-menu-item>
                      <el-menu-item index="/member-address">收货地址</el-menu-item>
                      <el-menu-item index="/member-coupon">我的优惠券</el-menu-item>
                      <el-menu-item index="/member-collect">我的收藏</el-menu-item>
                      <el-menu-item index="/member-integral">我的积分</el-menu-item>
                      <el-menu-item index="/member-invoice">发票设置</el-menu-item>
                    </el-submenu>
                  </el-menu>

                </el-col>

              </el-row>
            </div>
          </div>
          <!-- </div> -->
          <div style="clear:both;"></div>
        </div>
        <div style="width:100%;height:50px;background:#f5f5f5;"></div>
      </div>

      <div class="Center_home_page">
        <router-view></router-view>

      </div>

    </div>

  </div>
</template>

<script type="text/javascript">
import NavHeader from "../../components/layout/nav-header";

export default {
  data() {
    return {
      openeds: ["1"],
      member_type: "",
      activeName: "second",
      IntegralSum: 0,
      account: localStorage.getItem("account"),
      // orderList: '',
      tableData: [],
      multipleSelection: [],
      arr2: [],
      arr3: [],
      arr4: [],
      total: "0",
      // currentPage: 0,
      page: 1, //初始页
      totalCount: 1,
      pageSizes: [10],
      limit: 10,
      baseUrl: this.$config.baseUrl,
      SumNum: 0,
      dataList: "",

      info: {},
      photo: "",
      memberType: null,
      shopId: null,
      couponTotal: "-",
      collectTotal: "-",
      integralTotal: "-",

      sign: "签到领积分",
      navList: [
        {
          name: "待付款",
          path: "/my/order",
          query: { num: 1 },
          icon: "icon icon-paying"
        },
        {
          name: "待发货",
          path: "/my/order",
          query: { num: 2 },

          icon: "icon icon-delivery"
        },
        {
          name: "待收货",

          path: "/my/order",
          query: { num: 3 },

          icon: "icon icon-receiving"
        },
        {
          name: "已完成",
          path: "/my/order",
          query: { num: 4 },
          icon: "icon icon-complete"
        }
      ],
      servelist: []
    };
  },
  computed: {
    member() {
      return this.$store.state.member;
    },
    shop() {
      return this.$store.state.shop;
    }
  },
  methods: {
    //验证是否需要供需按钮
    isShowSupply() {
      if (this.member.shopId) {
        return true       
      } else {
        return false
      }
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    goTo(path) {
      this.$router.replace(path);
    },
    handleSizeChange(val) {
      // 改变每页显示的条数
      this.limit = val;
      // 点击每页显示的条数时，显示第一页
      this.getData(val, 1);
      // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.page = 1;
    },
    // 显示第几页
    handleCurrentChange(val) {
      // 改变默认的页数
      this.page = val;
      // 切换页码时，要获取每页显示的条数
      this.getData(this.limit, val * this.limit);
    },

    handleClick(tab, event) {},
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  },
  components: { NavHeader },
  created() {}
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common";
::v-deep .el-submenu__title {
  display: none;
}
.Center {
  width: 200;

  background: #f5f5f5;
  .Center-mine {
    width: 100%;
    margin: 0 auto;
    // min-height: 1000px;
    height: auto;
  }

  // overflow: hidden;
  .cur {
    background-color: #222;
    color: #fff;
  }

  // height: 1800px;
  .Center_left {
    float: left;
    width:200px;
    height: auto;

    background: #f5f5f5;

    .user-info {
      margin-top: 50px;
      width: 150px;
      height: 150px;

      .head_portrait {
        width: 180px;
        height: 100px;
        text-align: center;
        img {
          width: 100px;
          height: 100%;
        }
      }
    }

    .order {
      width: 100%;
      background: #fff;
      .el-row {
      width: 100%;
      }
      .el-col {
       width: 100%;
      }
      .el-menu {
        width: 70%;
        margin: 30px auto;
      }

      .el-menu-item {
        padding-left: 10px !important;
        font-size: 12px;
        font-weight: 700;
      }

      .el-menu-item:hover {
        background-color: #fff !important;
      }

      .el-menu-item.is-active {
        background-color: #fff !important;
      }

      .el-submenu .el-menu-item {
        min-width: 0px !important;
      }

      p {
        font-size: 20px;
        height: 30px;
        margin-left: 10px;
        margin-bottom: 20px;
        cursor: pointer;
      }

      .myOrder {
        .box {
          height: 125px;
          width: 180px;
          overflow: hidden;
          cursor: pointer;
        }

        .draw-enter-active,
        .draw-leave-active {
          transition: all 0.5s ease;
        }

        .draw-enter,
        .draw-leave-to {
          height: 0;
        }
      }

      .Collecting {
        .box {
          height: 35px;
          width: 180px;
          overflow: hidden;
          cursor: pointer;
        }

        .draw-enter-active,
        .draw-leave-active {
          transition: all 0.5s ease;
        }

        .draw-enter,
        .draw-leave-to {
          height: 0;
        }
      }

      .Aftermarket {
        .box {
          height: 40px;
          width: 180px;
          overflow: hidden;
          cursor: pointer;
        }

        .draw-enter-active,
        .draw-leave-active {
          transition: all 0.5s ease;
        }

        .draw-enter,
        .draw-leave-to {
          height: 0;
        }
      }

      .Account {
        .box {
          height: 180px;
          width: 180px;
          overflow: hidden;
          cursor: pointer;
        }

        .draw-enter-active,
        .draw-leave-active {
          transition: all 0.5s ease;
        }

        .draw-enter,
        .draw-leave-to {
          height: 0;
        }
      }
    }
  }

  .Center_right {
    height: 2000px;
    width: 1240px;
    float: left;
    background-color: yellow;

    .top {
      ul li {
        float: left;
        width: 240px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.bg {
  background: #f5f5f5;
}
.member {
  display: flex;
  justify-content: space-between;
  width: 1226px;
  margin: 0 auto;
  margin-top: 10px;
}
.Center_home_page {
  flex: 1;
  box-sizing: border-box;
  padding: 20px;
  background: #fff;
  margin-left: 10px;

  // height: 2500px;

  .evaluate-fa {
    height: 30px;
    margin: 10px 0 15px 0;
  }

  .evaluate {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .evaluate:hover {
    color: #f40;
    border-color: #f40;
  }
}

.Account_of_integral {
  height: 200px;
  width: 100%;
  justify-content: space-between;
  display: flex;

  .Account {
    box-shadow: 0 1px 6px #999;
    height: 190px;
    width: 520px;
    border: 1px solid #ccc;
    float: left;
  }

  // background-color:yellow;
  .head_portrait {
    img {
      width: 150px;
      height: 150px;
      // border-radius: 50%;
      margin: 15px 0 0 50px;
      float: left;
      border: 1px solid #ccc;
    }

    .message {
      float: left;
      margin-left: 40px;

      ul {
        height: 200px;
        padding: 40px 0;

        li {
          margin-bottom: 10px;
          cursor: pointer;
          a {
            color: #000;
          }
        }
      }
    }
  }
}

.indent {
  min-height: 500px;
  height: auto !important;
  overflow: hidden;
  margin-bottom: 30px;
  // height:500px;
  .Logistics {
    // border-bottom: 1px solid #ccc;
    .flow {
      height: 70px;
      width: 100%;
      padding-top: 10px;

      li {
        float: left;
        width: 20%;
        text-align: center;
        cursor: pointer;

        i {
          font-size: 40px;
        }

        i:hover {
          color: #e94c15;
        }
      }
    }
  }

  .Od {
    // height: 400px;
    width: 100%;
    overflow: hidden;
    // background: yellow;
  }
}

.hst {
  margin-top: 35px;
  height: 300px;
  width: 100%;
  // background-color:yellow;
  margin-top: 20px;

  h3 {
    margin-bottom: 20px;
    font-size: 18px;
  }

  ul {
    width: 100%;
    overflow: hidden;

    li {
      float: left;
      width: 250px !important;
      height: 280px;
      text-align: center;
      padding-top: 25px;
      border: 1px solid #e6e6e6;

      img {
        height: 200px;
        width: 248px;
      }

      p {
        margin-top: 10px;
        font-size: 18px;
      }
    }

    li:nth-child(n + 2) {
      margin-left: 30px;
    }
  }
}

.recommend_ggods {
  height: 300px;
  width: 100%;
  // background-color:yellow;
  margin-top: 70px;

  h3 {
    margin-bottom: 20px;
    font-size: 18px;
  }

  ul {
    width: 100%;

    li {
      width: 250px !important;
      height: 280px;
      text-align: center;
      padding-top: 25px;
      border: 1px solid #e6e6e6;
      float: left;

      img {
        height: 200px;
        width: 248px;
      }

      p {
        margin-top: 10px;
        font-size: 18px;
      }
    }

    li:nth-child(n + 2) {
      margin-left: 30px;
    }
  }
}
</style>
<style lang="scss">
.waning-row {
  float: left !important;
  padding: 0 !important;
  // margin: 10px 0 !important;
  width: 100% !important;
  // border: 1px solid #ccc !important;
}
.el-table .waning-row td {
  border: 1px solid #ccc;
}

.warning-row .el-table_1_column_1 {
  border-right: 1px solid #ccc !important;
  width: 44px !important;
}

.warning-row .el-table_1_column_2 .cell {
  width: 352px;
  padding: 10px;
}

.warning-row .el-table_1_column_3 {
  width: 127px !important;
  padding: 10px !important;
}

.el-table .warning-row .el-table_1_column_4 {
  width: 55px !important;
  padding: 10px !important;
  border: 1px solid #ccc;
}

.warning-row .el-table_1_column_5 {
  width: 118px !important;
  padding: 10px !important;
  border-right: 1px solid #ccc;
}

.warning-row .el-table_1_column_6 {
  width: 130px !important;
  padding: 10px !important;
  border-right: 1px solid #ccc;
}

.warning-row .el-table_1_column_7 {
  width: 120px !important;
  padding: 10px !important;
  border-right: 1px solid #ccc;
}

.warning-row .el-table_1_column_8 {
  width: 132px !important;
  padding: 10px !important;
  border-right: 1px solid #ccc !important;
}
// .el-table__body-wrapper ttr{
//   border: 1px solid #ccc;
// }
</style>
